<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div class="pages" v-loading="loading">
        <table-height-auto>
            <template slot="before">
                <el-form :inline="true" class="demo-form-inline">
                    <div class="search">
                        <div>
                          <el-form-item label="发布用户账号">
                              <el-input v-model="search.publishUserPhone" clearable placeholder="模糊搜索" style="width: 160px;"></el-input>
                          </el-form-item>
                          <el-form-item label="土地编码">
                              <el-input v-model="search.landCode" clearable placeholder="精确搜索" style="width: 160px;"></el-input>
                          </el-form-item>
                          <el-form-item label="土地编号">
                              <el-input v-model="search.landNo" clearable placeholder="模糊搜索" style="width: 160px;"></el-input>
                          </el-form-item>
                          <el-form-item label="服务类型">
                              <el-select v-model="search.serviceTypeId" placeholder="" style="width: 160px;">
                                    <el-option label="全部" value="" />
                                    <el-option
                                        v-for="item in serviceTypeList"
                                        :key="item.serviceTypeId"
                                        :label="item.name"
                                        :value="item.serviceTypeId">
                                    </el-option>
                              </el-select>
                          </el-form-item>
                          <el-form-item label="种子名称">
                              <el-input v-model="search.seedName" clearable placeholder="模糊搜索" style="width: 160px;"></el-input>
                          </el-form-item>
                          <el-form-item label="服务人员">
                            <el-input v-model="search.serviceUserName" clearable placeholder="模糊搜索" style="width: 160px;"></el-input>
                        </el-form-item>
                        </div>
                        <div>
                          <el-form-item>
                              <el-button type="primary" size="medium" @click="getList">查询</el-button>
                              <el-button type="primary" size="medium" @click="reset">重置</el-button>
                              <!-- <el-button size="mini"  type="primary" @click="checkDetail({})">查看详情</el-button> -->
                          </el-form-item>
                        </div>
                    </div>
                </el-form>
            </template>

            <template v-slot:table="row">
              <el-table v-if="row.height"  :height="row.heightText"  :data="tableData" style="width: 100%">
                <el-table-column type="index" width="50" label="序号" />

                <el-table-column prop="publishUserPhone" label="发布用户账号" width="200" align="center">
                    <template #default="scope">
                        <!-- <el-image v-if="scope.row && scope.row.servicePicture && scope.row.servicePicture.pics" class="avatar" fit="contain" :src="scope.row.servicePicture.pics[0]" style="width: 30px;height: 30px;" />
                        <el-image v-else class="avatar" fit="contain" src="http://imgs.cunzhiliao.com/village-cloud-files/img/mine/defaultAvatar.png" style="width: 30px;height: 30px;" /> -->
                        <el-image class="avatar" fit="contain" :src="scope.row.publishUserLogo || 'http://imgs.cunzhiliao.com/village-cloud-files/img/mine/defaultAvatar.png'" style="width: 30px;height: 30px;" />
                        {{ scope.row.publishUserPhone }}
                    </template>
                </el-table-column>
                <el-table-column prop="landCode" label="土地编码" show-overflow-tooltip width="120" />
                <el-table-column prop="landNo" label="土地编号" width="120" />

                <el-table-column prop="serviceTypeName" label="服务类型" width="120" />
                <el-table-column prop="seedName" label="种子名称" width="120" />
                <el-table-column prop="serviceLandCount" label="服务地块数量" width="120" />

                <el-table-column prop="seedFee" label="种子价格" width="120" />
                <el-table-column prop="serviceFee" label="服务价格"  />
                <el-table-column prop="serviceUserName" label="服务人员" width="120" >
                    <template #default="scope">
                        <div v-if="scope.row.serviceUserName">
                            <div><el-image class="avatar" fit="contain" :src="scope.row.serviceUserLogo || 'http://imgs.cunzhiliao.com/village-cloud-files/img/mine/defaultAvatar.png'" style="width: 30px;height: 30px;" /></div>
                            <div>{{ scope.row.serviceUserName }}</div>
                            <div>{{ scope.row.serviceUserMobile }}</div>
                        </div>
                        <div v-else>暂未绑定</div>
                    </template>
                </el-table-column>
                <el-table-column prop="status" label="状态">
                    <template #default="scope">
                        {{ statusList[scope.row.status - 1]  }}
                    </template>
                </el-table-column>
                <el-table-column prop="createTime" label="创建时间" width="160" />
                <el-table-column prop="date" label="操作" width="160" align="center" fixed="right">
                    <template slot-scope="scope">
                        <el-button size="mini"  type="primary" @click="checkDetail(scope.row, '编辑')" v-if="scope.row.status != 3">查看详情</el-button>
                        <el-button size="mini"  type="primary" @click="checkDetail(scope.row, '编辑')" v-if="scope.row.status == 3">查看详情并确认</el-button>
                    </template>
                </el-table-column>
              </el-table>
            </template>

            <template slot="after">
              <div class="paging-row">
                <el-pagination
                    v-if="total"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage"
                    :page-sizes="[5, 10, 15, 20]"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total">
                </el-pagination>
              </div>
            </template>
        </table-height-auto>
        <el-dialog title="查看" append-to-body style="z-index: 5001;" class="my-dialog" :visible.sync="isVisible" width="800px">
            <el-carousel v-if="isVisible" height="430px" :autoplay="false" trigger="click" :initial-index="initialIndex">
                <!-- <el-carousel-item v-for="item in list" :key="item"> -->
                <el-image v-if="showData.type == 1" :src="showData.img || 'http://imgs.cunzhiliao.com/village-cloud-files/img/mine/defaultAvatar.png'" fit="contain" style="width: 760px;height: 430px;"  />
                <video  v-else-if="showData.type == 2" :src="showData.video" controls="controls" style="width: 760px;height: 430px;" >
                    您的浏览器不支持视频播放
                </video>
                <el-empty v-else :image-size="180" description="数据格式有误！" />
                <!-- </el-carousel-item> -->
            </el-carousel>
            <template #footer>
                <el-button type="primary" @click="isVisible = false">确定</el-button>
            </template>
        </el-dialog>
        <addSeed @closeAdd="closeAdd" :addDialogVisible="addDialogVisible" :currentRow="currentRow" />
        <serviceDetail @closeDialog="closeDetail" :detailDialogVisible="detailDialogVisible" :currentRow="currentRowDetail" />
    </div>
</template>

<script>
import addSeed from "./components/addSeed.vue";
import serviceDetail from "./components/serviceDetail.vue";
import {URL} from '../../../config'

export default {
    name: 'landConfig',
    components: {
        addSeed,
        serviceDetail
    },
    data() {
        return {
            search: {
                publishUserPhone: '',
                landCode: '',
                landNo: '',
                serviceTypeId: '',
                seedName: '',
                serviceUserName: '',
                serviceUserId: '',
            },
            loading: false,
            isVisible: false,
            detailDialogVisible: false,
            tableData: [],
            currentPage: 1,
            total: 0,
            pageSize: 10,
            serviceTypeList: [],
            addDialogVisible: false,
            currentRow: {},
            currentRowDetail: {},
            statusList: ['待接单', '待服务', '待确认', '已完成', '已删除']
        }
    },
    methods: {
        getList() {
            this.axios.post(URL.farm.farmTaskPage, { pageNo: this.currentPage, pageSize: this.pageSize, ...this.search }).then(res=>{
                if(res.code === '0'){
                    this.tableData = res.data.records
                    this.total = res.data.total;
                }else{
                    this.$message.error(res.msg);
                }
            })
        },

        checkDetail(row) {
            this.detailDialogVisible = true;
            this.currentRowDetail = row;
        },

        closeDetail() {
            this.detailDialogVisible = false;
            this.currentRowDetail = {};
            console.log(this.currentRowDetail);
            this.getList();
        },

        edit(row) {
            this.addDialogVisible = true;
            this.currentRow = row;
        },

        remove(row) {
            this.$confirm('是否切换状态为上架？', '上下架提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$message({
                    type: 'success',
                    message: '删除成功!'
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },

        del() {
            this.$confirm('删除后对应前端共享农场不可查看该土地，是否确认删除？', '删除提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$message({
                    type: 'success',
                    message: '删除成功!'
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },

        closeAdd() {
            this.addDialogVisible = false;
            this.getList()
        },

        reset () {
            this.search = {
                publishUserPhone: '',
                landCode: '',
                landNo: '',
                serviceTypeId: '',
                seedName: '',
                serviceUserName: '',
                serviceUserId: '',
            }
            this.getList()
        },

        handleSizeChange(val,type){
            this.pageSize = val;
            this.currentPage = 1;
            this.getList()
        },

        handleCurrentChange(val,type) {
            this.currentPage = val;
            this.getList();
        },

        getfarmServiceTypePriceList() {
            this.axios.post(URL.farm.farmServiceTypePriceList, { pageNo: 1, pageSize: 999 }).then(res=>{
                console.log(res);
                if(res.code === '0'){
                    this.serviceTypeList = res.data;
                }else{
                    this.$message.error(res.msg);
                }
            })
        }
    },
    mounted() {
        this.getfarmServiceTypePriceList();
        this.getList();
    },

}
</script>

<style lang="less" scoped>
.pages {
    .search {
        display: flex;
        justify-content: space-between;

    }

}

.avatar {
    width: 60px;
    height: 60px;
    border-radius: 10px;
}
</style>

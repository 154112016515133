<template>
    <div>
        <el-dialog title="查看服务详情" :visible.sync="visible" width="1050px" :close-on-click-modal="false"
            :close-on-press-escape="false" center @close="close" top="5vh">
            <div style="height: 78vh;overflow-x: auto;">
                <div>
                    <span>服务土地</span><span>共{{ tableData.length }}块地,每块地{{ currentRow.plantLandArea }}m²</span>
                </div>
                <div class="content">
                    <div class="left">
                        <div v-for="(item, i) in tableData" :key="i" class="item">
                            <div v-if="JSON.stringify(item) != '{}'"
                                style="width: 79px;display: flex;flex-direction: column;justify-content: center;align-items: center;">
                                <div>
                                    <el-image style="width:69px;height:49px;" fit="cover" :src="item.seedPicture"
                                        v-if="item.seedPicture" />
                                    <el-icon class="el-icon-question" style="width:59px;height:45px;font-size: 30px;"
                                        v-else />
                                    <!-- <img src="../../../../components/public/query.svg" style="width:59px;heigth=59px;" /> -->
                                </div>
                                <div>
                                    <span
                                        style="display: inline-block;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;width: 100px;">{{
                                            item.serviceTypeName }}</span>
                                </div>
                            </div>
                            <div v-else style="width: 79px;line-height: 70px;">无需服务</div>
                        </div>
                    </div>
                    <div class="right">
                        <div class="mb-10">服务状态：{{ detailData.status == 1 ? '待接单' : detailData.status == 2 ? '待服务' :
                            detailData.status == 3 ? '待确认' : detailData.status == 4 ? '已完成' : detailData.status == 5 ?
                                '已删除' : '' }}</div>
                        <div class="mb-10">
                            发布服务用户：
                            <img :src="detailData.publishUserLogo" style="width: 19px;widht: 19px;object-fit: cover;" />
                            {{ detailData.publishUserPhone }}
                        </div>
                        <div class="mb-10">土地编号: {{ detailData.landNo }}</div>
                        <div class="mb-10">
                            农场地址:
                            {{ detailData.address }}
                        </div>
                        <div class="mb-10">服务类型: {{ detailData.taskType == 1 ? '播种' : detailData.taskType == 2 ? '服务' :
                            detailData.taskType == 3 ? '收获' : '' }}</div>
                        <div class="mb-10" v-if="detailData.status == 1">
                            种子:
                            <img :src="detailData.seedPicture" />
                            {{ detailData.seedName }}
                            (服务者需要购买种子)
                        </div>
                        <div class="mb-10">服务土地数量: {{ detailData.serviceLandCount }}</div>
                        <div class="mb-10" v-if="detailData.taskType == 2">种子费用: ￥{{ detailData.seedFee }}</div>
                        <div class="mb-10">服务费用: ￥{{ detailData.serviceFee }}</div>
                        <div class="mb-10">
                            服务人员:
                            <span v-if="detailData.status != 1"><img style="width: 60px;" :src="detailData.serviceUserLogo"
                                    v-if="detailData.serviceUserLogo" /> {{ detailData.serviceUserName }} {{
                                        detailData.serviceUserMobile }}</span>
                            <span v-else>暂未绑定</span>
                        </div>
                        <div style="margin-bottom: 20px;" v-if="detailData.status == 3 || detailData.status == 4">服务完成时间: {{
                            detailData.serviceFinishTime }}</div>

                        <div v-if="detailData.status == 3 || detailData.status == 4">服务收获</div>
                        <div
                            v-if="(detailData.status == 3 || detailData.status == 4) && (detailData != null && detailData.serviceHarvest != null && detailData.serviceHarvest.harvest != null)">
                            <div class="category" style="margin-bottom: 20px;"
                                v-for="(item, i) in detailData.serviceHarvest.harvest" :key="i">
                                <span>{{ item.goodsName }}</span><span>入库重量: {{ item.weight }}KG</span>
                            </div>
                        </div>
                        <div style="margin-bottom: 20px;" v-if="detailData.status == 3 || detailData.status == 4">服务说明：{{ detailData.serivceRemark }}</div>

                        <div v-if="detailData.status == 3 || detailData.status == 4">服务图片</div>
                        <div v-if="detailData.status == 3 || detailData.status == 4" class="imgs"
                            style="margin-bottom: 20px;">
                            <el-image v-for="(item, i) in detailData.servicePicture.pics" :key="i" class="img" :src="item"
                                :preview-src-list="detailData.servicePicture.pics" />
                        </div>
                        <div v-if="detailData.status == 3 || detailData.status == 4" class="mb-10">服务视频</div>
                        <div v-if="detailData.status == 3 || detailData.status == 4" class="mb-10">
                            <video v-if="detailData.serviceVideo.video" :src="detailData.serviceVideo.video"
                                controls="controls" style="width:100px;height:100px;">
                                您的浏览器不支持视频播放
                            </video>
                        </div>
                    </div>
                </div>
                <span slot="footer" class="dialog-footer" v-if="detailData.status == 3">
                    <el-button @click="close">取 消</el-button>
                    <el-button type="primary" @click="submit">确认已完成</el-button>
                </span>
            </div>

        </el-dialog>
        <!-- <el-dialog title="查看" append-to-body style="z-index: 5001;" class="my-dialog" :visible.sync="isVisible" width="800px">
            <el-carousel v-if="isVisible" height="430px" :autoplay="false" trigger="click" :initial-index="initialIndex">
                <el-image v-if="showData.type == 1" :src="showData.img" fit="contain" style="width: 760px;height: 430px;"  />
                <video  v-else-if="showData.type == 2" :src="showData.video" controls="controls" style="width: 760px;height: 430px;" >
                    您的浏览器不支持视频播放
                </video>
                <el-empty v-else :image-size="180" description="数据格式有误！" />
            </el-carousel>
            <template #footer>
                <el-button type="primary" @click="isVisible = false">确定</el-button>
            </template>
        </el-dialog> -->
    </div>
</template>

<script>
import { URL } from "../../../../config/url.supplier";

export default {
    name: '',
    props: {
        detailDialogVisible: {
            type: Boolean,
            default: false
        },
        currentRow: {
            type: Object,
            default: () => { }
        }
    },
    data() {
        return {
            visible: false,
            loading: false,
            radio: 1,
            index: null,
            tableData: [],
            plantLandArea: '',
            detailData: []
        }
    },
    methods: {
        getList() {
            this.axios.get(`${URL.farm.farmTaskGetById}${this.currentRow.id}`).then(res => {
                if (res.code === '0') {
                    this.detailData = res.data;
                    this.plantLandArea = res.data.plantLandArea;

                    let serviceTypeName = res.data.serviceTypeName;

                    let plantLandList = res.data.taskDetail.plantLandList;
                    let plantLandTotalCount = res.data.taskDetail.plantLandTotalCount;

                    for (let index = 0; index < plantLandTotalCount; index++) {
                        this.tableData.push({})
                        for (let index2 = 0; index2 < plantLandList.length; index2++) {
                            if ((index + 1) == plantLandList[index2].no) {
                                let params = plantLandList[index2]
                                params.serviceTypeName = serviceTypeName;
                                this.tableData[index] = params
                            }
                        }
                    }
                } else {
                    this.$message.error(res.msg);
                }
            })
        },

        close() {
            this.$emit('closeDialog')
            this.tableData = [];
        },

        submit() {
            this.axios.get(`${URL.farm.farmTtaskConfirmTask}/${this.currentRow.id}`).then(res => {
                if (res.code === '0') {
                    this.close()
                    this.$message({
                        message: "操作成功",
                        type: "success"
                    });
                } else {
                    this.$message.error(res.msg);
                }
            })
        },

    },

    watch: {
        currentRow: {
            handler: function (oldV, newV) {
                console.log(oldV);
            },
        },
        detailDialogVisible: function (val) {
            this.visible = val;
            if (val) this.getList();
        }
    },

    mounted() {

    }
}
</script>

<style lang="less" scoped>
.content {
    display: flex;

    .left {
        width: 600px;
        height: 100%;
        padding: 20px 0;
        display: flex;
        flex-wrap: wrap;
        overflow: auto;

        .item {
            height: 74px;
            padding: 10px 20px 5px 20px;
            background: #F5F5F5;
            // background: red;
            margin-right: 10px;
            margin-bottom: 10px;
            text-align: center;
            border-radius: 5px;
        }

    }

    .right {
        flex: 1;

        .mb-10 {
            margin-bottom: 15px;
        }

        div {
            margin-bottom: 10px;
        }

        .radio-item {
            display: block;
            padding-top: 20px;
        }

        .category {
            display: flex;
            justify-content: space-between;
        }

        .imgs {
            display: flex;
            flex-wrap: wrap;

            .img {
                width: 100px;
                height: 100px;
                margin-right: 10px;
            }
        }
    }
}</style>
